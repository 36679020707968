.bg-dashboard {
    background-image: url("../img/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.nav-header {
    position: relative;
    width: 100%;
    background-color: #35655D;
    box-sizing: border-box;
}

.text-header {
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    line-height: 36px;
}

.text-error {
  color: red;
  text-align: center;
}

.disabled {
  pointer-events: none;
  color: gray; /* Change the color to indicate it's disabled */
  text-decoration: none; /* Remove underline */
}

 .aktif {
    /* text-decoration: underline 4px !important;
    text-decoration-color: #65f968 !important; */
    background-color: #234a44 !important;
    padding: 10px ;
    border-radius: 10%;
  }
 .un-aktif:hover {
    text-decoration: underline 4px !important;
    text-decoration-color: #65f968 !important;
    padding: 10px ;
    text-underline-offset: 10px;
  }
 .un-aktif {
    /* text-decoration: underline 4px !important;
    text-decoration-color: #65f968 !important; */
    padding: 10px ;
  }


  .pagination {
    display: inline-block;
    background-color: #fff;
  }
  
  .pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    border: 1px solid #ddd;
  }

  .CrudSearch{
    border-radius: 15px !important;
  }
  .CrudButton{
    width: '40%' !important;
    height:'35px' !important;
  }

  .btnSearch{
    border-radius: 10px !important;
  }

  .dropdownStyle .dropdown-menu { 
     /* padding-right: 200px !important; */
     left:-532% !important
     
    /* font-size: 30px !important; */
  }
  
  .pagination a.active {
    background-color: #35655D;
    color: white;
    border: 1px solid #35655D;
    font-weight: bold;
  }
  
  .pagination a:hover:not(.active) {background-color: #ddd;}
  
  .pagination a:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  
  .pagination a:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }


.text-nav {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
}

.bg-footer {
    width: 100%;
    background-color: #35655D;
    box-sizing: border-box;
    padding: 20px;
}

.text-footer {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
}

.tinggi-content {
    min-height: 100vh !important;
    padding-top: 50px;
}

.card-bg {
    background-color: #D9D9D9;
}

/* Dekorasi Tab;e */
.card-decoration{
    border:2px  #D9D9D9 solid !important;
}
/* End Dekorasi Table */