.Login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  /* margin: 0;
  background-image: url("../img/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; */
}

.card-login {
  background: #D9D9D9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 350px;
}

.judul-login {
  color: #35655D;
  font-size: 24px;
  font-weight: 700;
  word-wrap: break-word;
  text-align: center;
}

.logo-login {
  display: flex;
  justify-content: center;
  align-items: center;
}

form {
  text-align: left;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.input-login {
  width: calc(100% - 12px);
  padding: 5px;
  border: 1px solid #000;
  border-radius: 10px;
  background: #fff;
}

.button-login {
  background: #35655D;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  font-size: 15px;
  font-weight: 700;
  word-wrap: break-word;
}