.landingMain {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  background-color: #f1f2e9;
}

.cardHero {
  background-color: #000;
  display: flex; /* Use flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 400px;
  border-radius: 15px;
  background-image: url("../img/tesbg.jpeg");
  background-size: cover; /* or specify dimensions like 100% */
  background-position: 50% 70%; 
}
.cardRow {
  border-radius: 4px;
  border: 2px solid #141318;
  height: 230px;
  /* height: 320px; */
}

.cardHero h1 {
  color:#f1f2e9d5;
  font-weight: 700;
}
.cardHero h5 {
  color:#f1f2e9d5;
  font-weight: 700;
}

.card-login {
  background: #D9D9D9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 350px;
}

.judul-login {
  color: #35655D;
  font-size: 24px;
  font-weight: 700;
  word-wrap: break-word;
  text-align: center;
}

.logo-login {
  display: flex;
  justify-content: center;
  align-items: center;
}

form {
  text-align: left;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.input-login {
  width: calc(100% - 12px);
  padding: 5px;
  border: 1px solid #000;
  border-radius: 10px;
  background: #fff;
}

.button-login {
  background: #35655D;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  font-size: 15px;
  font-weight: 700;
  word-wrap: break-word;
}